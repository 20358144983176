import {Alpine} from "alpinejs";
import {collapse} from "@alpinejs/collapse";
import EmblaCarousel from "embla-carousel";
import { addDotBtnsAndClickHandlers } from './Embla/EmblaCarouselDotButton';
import { addPrevNextBtnsClickHandlers } from './Embla/EmblaCarouselArrowButtons';

import '@css/main.css';

Alpine.plugin(collapse)
window.Alpine = Alpine;
Alpine.start()

/**
 * Redirect the user to the selected venue on
 * change for the "Choose a venue" select
 * box in the header.
 */
document.getElementById('venue-select').onchange = function() {
  window.location.href = this.value;
}

const newsSliderNodes = document.querySelectorAll('.news-slider');

if (newsSliderNodes.length) {
  newsSliderNodes.forEach(el => {
    const viewport = el.querySelector('.news-slider__viewport');
    const prevButtonNode = el.querySelector('.news-slider__prev');
    const nextButtonNode = el.querySelector('.news-slider__next');
    const dotsNode = el.querySelector('.news-slider__dots')
    const emblaApi = EmblaCarousel(viewport, {
      slidesToScroll: 1,
      breakpoints: {
        '(min-width:768px)': {slidesToScroll: 2},
        '(min-width:1024px)': {slidesToScroll: 3},
        '(min-width:1280px)': {slidesToScroll: 4},
      }
    });

    const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
      emblaApi,
      prevButtonNode,
      nextButtonNode
    )

    const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
      emblaApi,
      dotsNode
    )

    emblaApi.on('destroy', removePrevNextBtnsClickHandlers)
    emblaApi.on('destroy', removeDotBtnsAndClickHandlers)

  })
}

const venueVideo = document.getElementById('venue-video-iframe');

if (document.getElementById('open-venue-video')) {
  document.getElementById('open-venue-video')
    .addEventListener('click', ev => {
      ev.preventDefault();
      document.getElementById('venue-video').classList.remove('inactive');
      venueVideo.setAttribute('src', venueVideo.dataset.src);
    });
}

if (document.getElementById('close-venue-video')) {
  document.getElementById('close-venue-video')
    .addEventListener('click', ev => {
      ev.preventDefault();
      document.getElementById('venue-video-iframe')
        .contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
      document.getElementById('venue-video').classList.add('inactive');
      venueVideo.setAttribute('src', '');
    });
}
